var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useEffect, useRef, useState } from 'react';
import './CoachBot.scss';
import { Box, Button, Typography } from '@mui/material';
import TPTextInput from '../../../../tandym-web-common/src/shared/components/textinput/TPTextInput';
import TPSvgIcon from '../../../../tandym-web-common/src/shared/components/svg-icon/TPSvgIcon';
import { fontSizeDynamic } from '../../../../tandym-web-common/src/shared/components/fontsizeutils/fontSizeUtils';
import { chatTime } from '../../utilities/Time';
import { useShowContext } from '../../pages/RootLayout';
import { useAPI } from '../../../../tandym-web-common/src/shared/services/api/API';
import { TANDYM_CONSTANTS } from '../../shared/Constants';
import { useAppSelector } from '../../store/hook';
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import CloseIcon from '@mui/icons-material/Close';
var coachBot = 'Coach Bot';
var CoachBot = function () {
    var _a = useState([]), messages = _a[0], setMessages = _a[1];
    var _b = useState(''), userInput = _b[0], setUserInput = _b[1];
    var _c = useState(false), isTyping = _c[0], setIsTyping = _c[1];
    var _d = useState([]), chatbotSuggestions = _d[0], setChatbotSuggestions = _d[1];
    var coachBodyRef = useRef(null);
    var _e = useShowContext(), isShow = _e.isShow, setIsShow = _e.setIsShow;
    var authToken = useAppSelector(function (state) { return state.userAuth; }).authToken;
    var _f = useState(false), fullScreen = _f[0], setFullScreen = _f[1];
    var _g = useAPI(), httpGet = _g.httpGet, httpPost = _g.httpPost;
    var handleInputChange = function (event) {
        setUserInput(event.target.value);
    };
    var chatId = '1';
    var fetchChatBotMessages = function () { return __awaiter(void 0, void 0, void 0, function () {
        var response, error_1;
        var _a, _b;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    _c.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, httpGet("".concat(TANDYM_CONSTANTS.API_URLS.CANDIDATE_CHATBOT_GET), { chatId: chatId, includeSuggestions: true })];
                case 1:
                    response = _c.sent();
                    setMessages((_a = response === null || response === void 0 ? void 0 : response.response) === null || _a === void 0 ? void 0 : _a.chatHistory);
                    setChatbotSuggestions((_b = response === null || response === void 0 ? void 0 : response.response) === null || _b === void 0 ? void 0 : _b.suggestionTopics);
                    return [3 /*break*/, 3];
                case 2:
                    error_1 = _c.sent();
                    console.error('Error fetching chat bot messages', error_1);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    useEffect(function () {
        if (authToken && isShow)
            fetchChatBotMessages();
    }, [authToken, isShow]);
    var handleKeyDown = function (event) {
        if (event.key === 'Enter') {
            event.preventDefault();
            handleSendMessage(userInput, false);
        }
    };
    var handleSendMessage = function (message, isSuggestion) { return __awaiter(void 0, void 0, void 0, function () {
        var newMessage_1, dataObj, res, error_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!message.trim())
                        return [2 /*return*/];
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    newMessage_1 = {
                        messageId: "msg_".concat(String(messages.length + 1).padStart(3, '0')),
                        contactId: chatId,
                        message: message,
                        messageFrom: 'human',
                        messageTimestamp: chatTime(),
                    };
                    dataObj = {
                        chatId: chatId,
                        chatMessage: message,
                        conversationTopic: isSuggestion ? message : '',
                        incudeSuggestions: true,
                    };
                    return [4 /*yield*/, httpPost(TANDYM_CONSTANTS.API_URLS.CANDIDATE_CHATBOT_POST, dataObj)];
                case 2:
                    res = _a.sent();
                    if (!(res === null || res === void 0 ? void 0 : res.error)) {
                        setMessages(function (prev) { return __spreadArray(__spreadArray([], prev, true), [newMessage_1], false); });
                        setUserInput('');
                        setIsTyping(true);
                        setTimeout(function () {
                            setIsTyping(false);
                            var botMessage = {
                                messageId: "msg_".concat(String(messages.length + 2).padStart(3, '0')),
                                contactId: chatId,
                                message: 'Thank you for your message, we will get back to you shortly.',
                                messageFrom: 'ai',
                                messageTimestamp: chatTime(),
                            };
                            setMessages(function (prev) { return __spreadArray(__spreadArray([], prev, true), [botMessage], false); });
                        }, 1500);
                    }
                    return [3 /*break*/, 4];
                case 3:
                    error_2 = _a.sent();
                    console.error('Error sending message', error_2);
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var handleSuggestionClick = function (event) {
        var suggestion = event.currentTarget.textContent;
        if (suggestion)
            handleSendMessage(suggestion, true);
    };
    useEffect(function () {
        var _a;
        (_a = coachBodyRef.current) === null || _a === void 0 ? void 0 : _a.scrollTo({
            top: coachBodyRef.current.scrollHeight,
            behavior: 'smooth',
        });
    }, [messages, isShow]);
    var formatTimestamp = function (timestamp) {
        var date = new Date(timestamp);
        if (isNaN(date.getTime())) {
            return timestamp;
        }
        var isDateIncluded = timestamp.includes('-');
        if (isDateIncluded) {
            return "".concat(String(date.getMonth() + 1).padStart(2, '0'), "-").concat(String(date.getDate()).padStart(2, '0'), "-").concat(date.getFullYear(), " ").concat(date.toLocaleTimeString('en-US', {
                hour: '2-digit',
                minute: '2-digit',
                hour12: true,
            }));
        }
        else {
            return timestamp;
        }
    };
    return (_jsxs(_Fragment, { children: [_jsx(Box, __assign({ component: 'span', className: 'coachbot-toggle-btn', sx: {
                    right: {
                        xs: '5px',
                        sm: '10px',
                        md: '20px',
                        lg: '20px',
                    },
                    bottom: {
                        xs: '5px',
                        sm: '10px',
                        md: '8px',
                        lg: '20px',
                    },
                }, onClick: function () { return setIsShow(!isShow); } }, { children: isShow ? (_jsx(TPSvgIcon, { resourceName: 'coach-bot-close-icon' })) : (_jsx(TPSvgIcon, { resourceName: 'chat-icon' })) })), isShow && (_jsx(Box, __assign({ className: 'coachbot-overlay' }, { children: _jsxs(Box, __assign({ className: 'coachbot-container', sx: {
                        width: fullScreen ? '94%' : '450px',
                        height: fullScreen ? '-webkit-fill-available' : 'initial',
                        right: {
                            xs: '2%',
                            sm: '3%',
                            md: '35px',
                            lg: '42px',
                            xl: '46px',
                        },
                        bottom: {
                            xs: '2%',
                            sm: '3%',
                            md: '18px',
                            lg: '40px',
                            xl: '50px',
                        },
                        top: {
                            xs: fullScreen ? '2%' : 'initial',
                            sm: fullScreen ? '3%' : 'initial',
                        },
                        maxWidth: {
                            xs: '96%',
                            sm: '95%',
                            md: '100%',
                        },
                    } }, { children: [_jsx(Box, __assign({ className: 'coachbot-header' }, { children: _jsxs(Box, __assign({ sx: {
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                } }, { children: [_jsxs(Box, __assign({ sx: {
                                            display: 'flex',
                                            alignItems: 'center',
                                            gap: '10px',
                                        } }, { children: [_jsxs(Box, __assign({ className: 'coachbot-admin' }, { children: [_jsx(TPSvgIcon, { resourceName: 'coach-bot-admin-icon' }), _jsx(Box, { className: 'coachbot-admin-status' })] })), _jsx(Typography, __assign({ sx: {
                                                    fontSize: fontSizeDynamic(20),
                                                    fontWeight: 400,
                                                } }, { children: "Coach Bot" }))] })), _jsxs(Box, __assign({ className: 'coachbot-options', sx: { display: 'flex' } }, { children: [_jsx(Box, { children: !fullScreen ? (_jsx(OpenInFullIcon, { onClick: function () { return setFullScreen(true); }, sx: { fontSize: fontSizeDynamic(24) } })) : (_jsx(CloseFullscreenIcon, { onClick: function () { return setFullScreen(false); }, sx: { fontSize: fontSizeDynamic(24) } })) }), _jsx(CloseIcon, { onClick: function () { return setIsShow(false); }, sx: { fontSize: fontSizeDynamic(24) } })] }))] })) })), _jsxs(Box, __assign({ className: 'coachbot-body', ref: coachBodyRef, sx: {
                                height: {
                                    xs: fullScreen ? 'calc(100% - 267px)' : '240px',
                                    sm: fullScreen ? 'calc(100% - 320px)' : '320px',
                                    xl: fullScreen ? 'calc(100% - 280px)' : '280px',
                                },
                            } }, { children: [messages === null || messages === void 0 ? void 0 : messages.map(function (msg, index) { return (_jsxs(Box, __assign({ className: "chat-message-container ".concat(msg.messageFrom === 'ai' ? 'bot-message-container' : 'user-message-container') }, { children: [msg.messageFrom === 'ai' && (_jsx(Box, __assign({ className: 'bot-icon' }, { children: _jsx(TPSvgIcon, { resourceName: 'coach-bot-admin-icon' }) }))), _jsxs(Box, { children: [_jsx(Typography, __assign({ component: 'div', className: "chat-message ".concat(msg.messageFrom === 'ai' ? 'bot-message' : 'user-message'), sx: {
                                                        fontSize: fontSizeDynamic(18),
                                                    } }, { children: msg.message })), _jsxs(Box, __assign({ className: 'chat-time' }, { children: [_jsx(Typography, __assign({ variant: 'caption', sx: {
                                                                fontSize: fontSizeDynamic(14),
                                                                marginRight: '4px',
                                                            } }, { children: formatTimestamp(msg.messageTimestamp) })), msg.messageFrom !== 'ai' && (_jsx(TPSvgIcon, { resourceName: 'chat-double-tick' }))] }))] })] }), 'chat-msg' + index)); }), isTyping && (_jsxs(Box, __assign({ className: 'chat-message-container bot-message-container' }, { children: [_jsx(Box, __assign({ className: 'bot-icon' }, { children: _jsx(TPSvgIcon, { resourceName: 'coach-bot-admin-icon' }) })), _jsx(Box, { children: _jsx(Typography, __assign({ className: 'chat-message bot-message', sx: {
                                                    paddingBottom: '0px !important',
                                                } }, { children: _jsxs(Typography, __assign({ component: 'span', className: 'typing-dots' }, { children: [_jsx(Typography, __assign({ component: 'span', sx: { fontSize: fontSizeDynamic(36) } }, { children: "\u2022" })), _jsx(Typography, __assign({ component: 'span', sx: { fontSize: fontSizeDynamic(36) } }, { children: "\u2022" })), _jsx(Typography, __assign({ component: 'span', sx: { fontSize: fontSizeDynamic(36) } }, { children: "\u2022" }))] })) })) })] })))] })), _jsx(Box, __assign({ className: 'coachbot-suggestions' }, { children: _jsx("ul", { children: chatbotSuggestions === null || chatbotSuggestions === void 0 ? void 0 : chatbotSuggestions.map(function (suggestion, index) { return (_jsx("li", __assign({ style: {
                                        fontSize: fontSizeDynamic(16),
                                        fontWeight: 500,
                                    }, onClick: handleSuggestionClick }, { children: suggestion }), coachBot + index)); }) }) })), _jsx(Box, __assign({ className: 'coachbot-input-container' }, { children: _jsxs(Box, __assign({ className: 'coachbot-input' }, { children: [_jsx(TPTextInput, { placeholder: 'Type something\u2026', multiline: true, rows: 3, isSpecialCharRequired: false, value: userInput, autoFocus: true, onKeyDown: handleKeyDown, onChange: handleInputChange, id: 'coachbot-input', inputProps: {
                                            'data-testid': "coachbot-input",
                                            sx: {
                                                fontSize: fontSizeDynamic(18),
                                            },
                                        } }), _jsx(Button, __assign({ disabled: !userInput, className: 'coachbot-send-button', onClick: function () { return handleSendMessage(userInput, false); } }, { children: _jsx(TPSvgIcon, { resourceName: 'coach-bot-send-icon' }) }))] })) }))] })) })))] }));
};
export default CoachBot;
