import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { createBrowserRouter, Navigate } from 'react-router-dom';
import RootLayout from './pages/RootLayout';
import Home from './pages/Home';
import ErrorPage from '../../tandym-web-common/src/pages/ErrorPage';
import { TANDYM_CONSTANTS } from './shared/Constants';
import { tabs } from './__mocks__/Tabs.mock';
import TimesheetsAndExpenses from '../../tandym-web-common/src/pages/timesheets-expenses/TimesheetsAndExpenses';
import Profile from './pages/profile/Profile';
import PersonalDetails from './pages/profile/PersonalDetails';
import ProfileSummary from './pages/profile/Summary';
import Preference from './pages/profile/preference/Preference';
import Placements from './pages/placements/placements';
import PlacementsDetails from './pages/placements/placementsDetails';
import Skills from './pages/profile/skills/Skills';
import Login from '../../tandym-web-common/src/pages/Login';
import { useAppSelector, useAppDispatch } from './store/hook';
import { setLogIn, setLogOut } from './store/authSlice';
import Compliance from './pages/placements/compliance/Compliance';
import InfoCenter from './pages/info-center/InfoCenter';
import WhoWeAre from './pages/info-center/WhoWeAre';
import Training from './pages/info-center/Training';
import TimeReporting from './pages/info-center/TimeReporting';
import Learning from './pages/info-center/Learning';
import Contact from './pages/info-center/Contact';
import Faqs from './pages/faqs/Faqs';
import FAQComponent from './pages/faqs/FAQComponent';
import { healthBenefitsFaqs, sickTimeFaqs, timesheetFaqs, } from './__mocks__/Faqs.mock';
import LearningTab from './pages/learningTab/LearningTab';
import LearningPathways from './pages/learningTab/LearningPathways';
import TandymCoach from './pages/learningTab/TandymCoach';
import LearningResources from './pages/learningTab/LearningResources';
import ResourcesPolicies from './pages/info-center/ResourcesPolicies';
import Pay from './pages/pay/Pay';
import ProtectedRoute from '../../tandym-web-common/src/shared/utilities/ProtectedRoute';
var portal = 'candidate';
var loginURL = TANDYM_CONSTANTS.API_URLS.GENERATE_LOGIN_LINK;
var acceptTermsURL = TANDYM_CONSTANTS.API_URLS.ACCEPT_TERMS_POST;
var najaxaURL = TANDYM_CONSTANTS.API_URLS.NAJAXA_API;
var validateOTPURL = TANDYM_CONSTANTS.API_URLS.VALIDATE_OTP;
var LoginWithRedux = function () {
    var dispatch = useAppDispatch();
    var updateEmail = function (email) {
        dispatch(setLogOut());
        dispatch(setLogIn({ email: email }));
    };
    var getApiResponse = function (response) {
        if (response.error) {
            dispatch(setLogOut());
        }
        else {
            dispatch(setLogIn({
                candidateId: response.mercuryContactId,
                userId: response.userId,
                isLoggedIn: true,
                authToken: response.authToken,
                isEmailLinkExpired: false,
                googleTrackingId: response.googleTrackingId,
                isTermsAccepted: response.latestTermsAccepted,
                NajaxaTimeSheetURL: response.fEEnvironment.NajaxaTimeSheetURL,
                paycheckURL: response.fEEnvironment.PaycheckURL,
                taskHumanURL: response.fEEnvironment.TaskHumanURL,
            }));
        }
    };
    var _a = useAppSelector(function (state) { return state.userAuth; }), isEmailLinkExpired = _a.isEmailLinkExpired, email = _a.email;
    var authTokenFromSessionStorage = sessionStorage.getItem('authToken');
    if (authTokenFromSessionStorage) {
        return _jsx(Navigate, { to: '/home' });
    }
    return (_jsx(Login, { portal: portal, loginURL: loginURL, updateEmail: updateEmail, isExpired: isEmailLinkExpired, email: email || '', validateOTPURL: validateOTPURL, getApiResponse: getApiResponse }));
};
var TermsAndConditionswithRedux = function () {
    var _a = useAppSelector(function (state) { return state.userAuth; }), email = _a.email, NajaxaTimeSheetURL = _a.NajaxaTimeSheetURL;
    return (_jsx(TimesheetsAndExpenses, { portal: portal, najaxaURL: najaxaURL, email: email, najaxaTimeSheetURL: NajaxaTimeSheetURL }));
};
var ProtectedRouteWrapper = function (_a) {
    var element = _a.element;
    return _jsx(ProtectedRoute, { children: element });
};
var router = createBrowserRouter([
    {
        path: '/',
        element: _jsx(RootLayout, {}),
        errorElement: _jsx(ErrorPage, { portal: 'candidate', tabs: tabs }),
        children: [
            { path: '', element: _jsx(Navigate, { to: '/login' }) },
            {
                path: 'login',
                element: _jsx(LoginWithRedux, {}),
            },
            {
                path: 'timesheets-expenses',
                element: (_jsx(ProtectedRouteWrapper, { element: _jsx(TermsAndConditionswithRedux, {}) })),
            },
            {
                path: 'home',
                element: (_jsx(ProtectedRouteWrapper, { element: _jsx(Home, { portal: portal, acceptTermsURL: acceptTermsURL }) })),
            },
            {
                path: '/profile',
                element: _jsx(ProtectedRouteWrapper, { element: _jsx(Profile, {}) }),
                children: [
                    { path: '/profile/personal-details', element: _jsx(PersonalDetails, {}) },
                    { path: '/profile/summary', element: _jsx(ProfileSummary, {}) },
                    { path: '/profile/preference', element: _jsx(Preference, {}) },
                    { path: '/profile/skills', element: _jsx(Skills, {}) },
                ],
            },
            {
                path: '/placements',
                element: _jsx(ProtectedRouteWrapper, { element: _jsx(Placements, {}) }),
                children: [
                    {
                        path: '/placements/placements-details',
                        element: _jsx(PlacementsDetails, {}),
                    },
                    {
                        path: '/placements/compliance',
                        element: _jsx(Compliance, {}),
                    },
                ],
            },
            {
                path: '/learning',
                element: _jsx(ProtectedRouteWrapper, { element: _jsx(LearningTab, {}) }),
                children: [
                    {
                        path: '/learning/learning-pathways',
                        element: _jsx(LearningPathways, {}),
                    },
                    {
                        path: '/learning/tandym-coach',
                        element: _jsx(TandymCoach, {}),
                    },
                    {
                        path: '/learning/learning-resources',
                        element: _jsx(LearningResources, {}),
                    },
                ],
            },
            {
                path: '/info-center',
                element: _jsx(ProtectedRouteWrapper, { element: _jsx(InfoCenter, {}) }),
                children: [
                    { path: '/info-center/who-we-are', element: _jsx(WhoWeAre, {}) },
                    { path: '/info-center/training', element: _jsx(Training, {}) },
                    { path: '/info-center/time-reporting', element: _jsx(TimeReporting, {}) },
                    {
                        path: '/info-center/resources-policies',
                        element: _jsx(ResourcesPolicies, {}),
                    },
                    { path: '/info-center/learn', element: _jsx(Learning, {}) },
                    { path: '/info-center/contact', element: _jsx(Contact, {}) },
                ],
            },
            {
                path: '/faqs',
                element: _jsx(ProtectedRouteWrapper, { element: _jsx(Faqs, {}) }),
                children: [
                    { path: '', element: _jsx(Navigate, { to: '/faqs/sick-time' }) },
                    {
                        path: '/faqs/sick-time',
                        element: _jsx(FAQComponent, { faqs: sickTimeFaqs }),
                    },
                    {
                        path: '/faqs/timesheet',
                        element: _jsx(FAQComponent, { faqs: timesheetFaqs }),
                    },
                    {
                        path: '/faqs/health-benefits',
                        element: _jsx(FAQComponent, { faqs: healthBenefitsFaqs }),
                    },
                ],
            },
            {
                path: '/pay',
                element: _jsx(ProtectedRouteWrapper, { element: _jsx(Pay, {}) }),
            },
        ],
    },
]);
export default router;
